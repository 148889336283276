<template>
	<Page class="page-register">
		<LayoutAuth
				register="true"
				title="Register"
		>
			<v-form
					ref="form"
					v-model="valid"
					lazy-validation
			>
				<v-text-field
						v-model="username"
						label="Username"
						:rules="[
                          validationRules.required,
                          validationRules.username.start,
                          validationRules.username.characters,
                          validationRules.username.min,
                        ]"
						required
						color="none"
                        @keydown.space.prevent
				></v-text-field>
				<v-text-field
						v-model="email"
						label="Email"
                        type="email"
						:rules="[
                          validationRules.required,
                          validationRules.email
                        ]"
						required
						color="none"
                        @keydown.space.prevent
				></v-text-field>
				<v-text-field
						v-model="password"
						label="Password"
						:rules="[
                          validationRules.required,
                          validationRules.password.lowercase,
                          validationRules.password.uppercase,
                          validationRules.password.digit,
                          validationRules.password.min,
                        ]"
						required
						@click:append="showPassword = !showPassword"
						:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
						:type="showPassword ? 'text' : 'password'"
						color="none"
                        @keydown.space.prevent
				></v-text-field>
				<v-text-field
						v-model="passwordConfirmation"
						type="password"
						label="Confirm Password"
						:rules="[
                          validationRules.required,
                          password === passwordConfirmation || 'Password must match'
                        ]"
						required
						color="none"
                        @keydown.space.prevent
				></v-text-field>
				<v-btn
						type="submit"
						large
						outlined
						:disabled="!valid || auth.register.isLoading"
						:loading="auth.register.isLoading"
						@click.prevent="requestRegisterHandler"
						class="btn-submit"
				>
					Submit
				</v-btn>
			</v-form>

		</LayoutAuth>
	</Page>
</template>

<script>
	import {
		mapActions,
		mapState
	} from 'vuex';
	import Page from "../components/layouts/LayoutPage";
	import LayoutAuth from "../components/layouts/LayoutAuth";
	import { mixinValidationRules } from "../mixins/mixinValidationRules";

	export default {
		name: 'Register',
		components: { LayoutAuth, Page },
		mixins: [
			mixinValidationRules,
		],
		data() {
			return {
				valid: false,
				username: '',
				email: '',
				password: '',
				showPassword: false,
				passwordConfirmation: '',
			};
		},
		computed: {
			...mapState( [
				'auth'
			] ),
		},
		methods: {
			...mapActions( [
				'requestRegister',
			] ),
			requestRegisterHandler() {
				if ( this.$refs.form.validate() ) {
					this.requestRegister( {
						name: this.username,
						email: this.email.toLowerCase(),
						password: this.password,
						password_confirmation: this.passwordConfirmation,
					} );
				}
			},
		},
	};
</script>
