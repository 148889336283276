var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',{staticClass:"page-register"},[_c('LayoutAuth',{attrs:{"register":"true","title":"Register"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"label":"Username","rules":[
                          _vm.validationRules.required,
                          _vm.validationRules.username.start,
                          _vm.validationRules.username.characters,
                          _vm.validationRules.username.min ],"required":"","color":"none"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();}},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('v-text-field',{attrs:{"label":"Email","type":"email","rules":[
                          _vm.validationRules.required,
                          _vm.validationRules.email
                        ],"required":"","color":"none"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{attrs:{"label":"Password","rules":[
                          _vm.validationRules.required,
                          _vm.validationRules.password.lowercase,
                          _vm.validationRules.password.uppercase,
                          _vm.validationRules.password.digit,
                          _vm.validationRules.password.min ],"required":"","append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"color":"none"},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-text-field',{attrs:{"type":"password","label":"Confirm Password","rules":[
                          _vm.validationRules.required,
                          _vm.password === _vm.passwordConfirmation || 'Password must match'
                        ],"required":"","color":"none"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();}},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}}),_c('v-btn',{staticClass:"btn-submit",attrs:{"type":"submit","large":"","outlined":"","disabled":!_vm.valid || _vm.auth.register.isLoading,"loading":_vm.auth.register.isLoading},on:{"click":function($event){$event.preventDefault();return _vm.requestRegisterHandler($event)}}},[_vm._v(" Submit ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }